import { Box, styled } from "@mui/material";
export const InstituteTableSection = styled(Box)(({ theme }) => ({
  position: "relative",
  ".institute-title": {
    position: "relative",
    paddingBottom: "30px",
    marginBottom: "20px",
    ".imain-title": {
      marginBottom: "0",
      paddingBottom: "15px",
      "&:before,&:after": {
        display: "none",
      },
    },
    ".sub-title": {
      textAlign: "center",
      width: "45%",
      margin: "0 auto",
      fontWeight: "500",
      lineHeight: "28px",
      fontSize: "18px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    "&:before": {
      content: "''",
      position: "absolute",
      width: "90px",
      height: "3px",
      backgroundColor: theme.colors.secondaryColor,
      borderRadius: "15px",
      left: "50%",
      bottom: "3px",
      transform: "translate(-50%, 0%)",
    },
    "&:after": {
      content: "''",
      position: "absolute",
      width: "60px",
      height: "10px",
      backgroundColor: theme.colors.primaryColor,
      borderRadius: "15px",
      left: "50%",
      bottom: "0",
      transform: "translate(-50%, 0%)",
    },
  },
  ".MuiFormControl-root": {
    marginTop: "0",
    marginBottom: "0",
  },
  ".conditions-table": {
    boxShadow: "none",
    border: `1px solid ${theme.colors.primaryColor}`,
    borderRadius: "12px",
    tabel: {
      [theme.breakpoints.down("md")]: {
        minWidth: "550px",
      },
    },
    thead: {
      backgroundColor: theme.colors.primaryColor,
      tr: {
        th: {
          color: theme.colors.white,
          padding: `10px 10px`,
          fontSize: "16px",
          fontWeight: "600",
          textTransform: "capitalize",
          "&:nth-child(1)": {
            width: "20%",
          },
          "&:nth-child(2)": {
            width: "30%",
          },
          "&:nth-child(3)": {},
          "&:nth-child(4)": {
            width: "10%",
          },
          "&:nth-child(5)": {
            width: "25%",
          },
          "&:nth-child(6)": {
            width: "30%",
          },
          [theme.breakpoints.down("md")]: {
            padding: `5px 12px`,
            fontSize: "14px",
          },
          "&.table-main-title": {
            [theme.breakpoints.down("md")]: {
              width: "auto",
            },
          },
        },
      },
    },
    tbody: {
      tr: {
        "th,td": {
          padding: `10px 10px`,
          fontSize: "14px",
          lineHeight: "18px",
          [theme.breakpoints.down("md")]: {
            padding: `5px 12px`,
            fontSize: "14px",
            lineHeight: "18px",
          },
        },
      },
    },
  },
  ".about-round-effect": {
    width: "450px",
    height: "auto",
    position: "absolute",
    top: "-60px",
    right: "-90px",
    zIndex: "-1",
  },
  ".about-dote-effect": {
    width: "210px",
    height: "200px",
    position: "absolute",
    bottom: "130px",
    left: "-20px",
    zIndex: "-1",
    overflow: "hidden",
  },
  ".table-pagination": {
    ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
      fontSize: "14px",
      fontWeight: "500",
    },
    ".MuiSelect-select": {
      color: "#000 !important",
      fontSize: "14px !important",
      fontWeight: "500 !important",
      background: "transparent",
      border: "1px solid #ddd",
      borderRadius: "5px",
    },
    ".MuiTablePagination-selectIcon": {
      fill: "#999999",
    },
    ".MuiButtonBase-root": {
      backgroundColor: "#ddd",
      color: "white",
      width: "30px",
      height: "30px",
      borderRadius: "5px",
      marginRight: "8px",
      ".MuiSvgIcon-root": {
        fill: "black",
        fontSize: "22px",
      },
    },
  },
  ".institute-table-form-section": {
    position: "relative",
    padding: "30px 30px",
    border: "1px solid #ffffff",
    borderRadius: "10px",
    overflow: "hidden",
    marginBottom: "40px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 20px",
    },
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      overflow: "hidden",
      background: `linear-gradient(90deg, rgba(248,222,219,1) 30%, rgba(226,239,249,1) 70%)`,
      backgroundRepeat: "no-repeat",
      zIndex: "-1",
      top: "0",
      left: "0",
    },
  },
  ".institute-table-form-btn": {
    textAlign: "center",
    ".institute-btns": {
      width: "220px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
}));
