import { Box, Typography, keyframes, styled } from "@mui/material";

export const Title = styled(Typography)(({ theme }) => ({
  position: "relative",
  textAlign: "center",
  paddingBottom: "40px",
  fontSize: "42px",
  fontWeight: "700",
  lineHeight: "48px",
  color: theme.colors.primaryColor,
  maxWidth: "850px",
  margin: "0 auto 50px",
  [theme.breakpoints.down("lg")]: {
    fontSize: "38px",
    lineHeight: "40px",
    maxWidth: "100%",
    paddingBottom: "35px",
    margin: "0 auto 45px",
  },
  "&:before": {
    content: "''",
    position: "absolute",
    width: "90px",
    height: "3px",
    backgroundColor: theme.colors.secondaryColor,
    borderRadius: "15px",
    left: "50%",
    bottom: "3px",
    transform: "translate(-50%, 0%)",
    [theme.breakpoints.down("lg")]: {
      // width: "60px",
    },
  },
  "&:after": {
    content: "''",
    position: "absolute",
    width: "60px",
    height: "10px",
    backgroundColor: theme.colors.primaryColor,
    borderRadius: "15px",
    left: "50%",
    bottom: "0",
    transform: "translate(-50%, 0%)",
    [theme.breakpoints.down("lg")]: {
      // width: "50px",
    },
  },
  "&.about-us-title": {
    textAlign: "left",
    paddingBottom: "0",
    marginBottom: "40px",
    [theme.breakpoints.down("xl")]: {
      fontSize: "30px",
      lineHeight: "36px",
      marginBottom: "15px",
    },
    [theme.breakpoints.down("lg")]: {
      textAlign: "left",
      paddingBottom: "0",
    },
    "&:before": {
      display: "none",
    },
    "&:after": {
      display: "none",
    },
  },
}));

export const SectionSapce = styled(Box)(({ theme }) => ({
  "&.space-top": {
    paddingTop: "100px",
    [theme.breakpoints.down("xl")]: {
      paddingTop: "80px",
    },
  },
  "&.space-bottom": {
    paddingBottom: "100px",
    [theme.breakpoints.down("xl")]: {
      paddingBottom: "80px",
    },
  },
}));

export const CenterBtnBox = styled(Box)(({ theme }) => ({
  position: "relative",
  textAlign: "center",
  marginTop: "30px",
}));

export const LeftBtnBox = styled(Box)(({ theme }) => ({
  position: "relative",
  textAlign: "left",
  marginTop: "30px",
}));

export const PointIconBox = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  border: "1px solid #dddddd",
  borderRadius: "15px",
  padding: "10px 10px 10px 0px",
  marginLeft: "35px",
  marginBottom: "35px",
  [theme.breakpoints.down("md")]: {
    padding: "10px 10px 10px 0px",
  },
  ".points-icon-box": {
    width: "75px",
    height: "70px",
    overflow: "hidden",
    position: "relative",
    marginLeft: "-35px",
    backgroundColor: theme.colors.white,
    border: "1px solid #ddd",
    borderRadius: "12px",
    padding: "12px 12px",
    marginRight: "20px",
    [theme.breakpoints.down("md")]: {
      width: "65px",
      height: "55px",
      marginRight: "15px",
      padding: "8px 8px",
    },
    ".points-icon": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
  ".points-texts": {
    flex: "1",
    fontSize: "16px",
    fontWeight: "500",
    color: theme.colors.textColor,
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
    },
  },
}));
