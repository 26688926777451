import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  AvailableBranches,
  AvailableInstitutes,
  AvailablePriceRange,
  AvailablePrograms,
  PAGE_ROUTES,
} from "helpers/constant";
import { SectionSapce, Title } from "styles/General.styled";
import { InstituteTableSection } from "./InstituteTable.styled";
import { Fade } from "react-awesome-reveal";
import { useRouter } from "next/router";
import { Institutes, ProgramNames } from "helpers/InstitutesData";
import { RoundLogoAnimation } from "ui";
import { ArrowRightOutlineIcon, PhoneIcon } from "ui/icons";
import WP from "../../../../public/assets/images/wp.webp";
import Image from "next/image";
import Link from "next/link";
interface Column {
  id: "InstituteName" | "ProgramName" | "Campus" | "Intake" | "Fees";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "InstituteName", label: "Institute Name" },
  { id: "ProgramName", label: "Program Name" },
  {
    id: "Campus",
    label: "Campus",
  },
  {
    id: "Intake",
    label: "Intake",
  },
  {
    id: "Fees",
    label: "Fees",
  },
];

declare interface InstituteTableProps {
  sectionClass?: string;
}
// declare interface OptionProps {
//   label: string;
//   value: string;
// }

const InstituteTable = ({
  sectionClass = "space-top",
}: InstituteTableProps) => {
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  // const [branches, setBranches] = React.useState<Array<OptionProps>>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [price, setPrice] = React.useState("");
  const [program, setProgram] = React.useState("");
  const [institute, setInstitute] = React.useState("");
  const [city, setCity] = React.useState("");
  const [appliedSearch, setAppliedSearch] = React.useState(false);

  const router = useRouter();
  const checkProgramInstitute = () => {
    const data = PAGE_ROUTES.find(
      (el) => el.url == router.asPath.replace("/", "")
    );
    return data;
  };
  const data = checkProgramInstitute();
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  React.useEffect(() => {
    const data = checkProgramInstitute();
    if (data?.isInstitute) {
      const idata = Institutes.find((el) => el.url == router.asPath);
      setInstitute(idata?.name || "");
    }

    if (data?.isProgram) {
      const pData = ProgramNames.find((el) => el.url == router.asPath);
      setProgram(pData?.name || "");
    }
  }, []);

  const fetchData = async () => {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/api/institutes?InstituteName=${institute}&ProgramName=${program}&Campus=${city}&Fees=${price}`
    );
    const rows = await res.json();
    setRows(rows);
    setAppliedSearch(true);
    setPage(0);
  };

  const enquiryNow = (data: any) => {
    window.open(`https://api.whatsapp.com/send?phone=61404797620&text=Hey, I contacted you through the PY website. for the Enquiry of 
    ${data?.ProgramName} in ${data?.InstituteName} for the campus ${data?.Campus} and Intake ${data.Intake}`, "_blank");
  }
  return (
    <SectionSapce className={sectionClass}>
      <InstituteTableSection>
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item sm={12} xs={12}>
              <Box className="institute-title">
                <Fade damping={0} direction="up" triggerOnce={true} delay={400}>
                  <Title variant="h1" className="imain-title">
                    Institute Finder
                  </Title>
                </Fade>
                <Fade damping={0} direction="up" triggerOnce={true} delay={400}>
                  <Typography variant="h2" className="sub-title">
                    Select the program and city below to view the approved
                    professional year providers.
                  </Typography>
                </Fade>
              </Box>
              <RoundLogoAnimation className="about-round-effect" />
            </Grid>
            <Grid item xs={12}>
              <Box className="institute-table-form-section">
                <Grid container spacing={3}>
                  {!data?.isInstitute && (
                    <Grid
                      item
                      lg={!data?.isProgram ? 3 : 4}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <Fade damping={0} direction="up" triggerOnce={true} delay={400}>
                        <TextField
                          id="enroll-select-Institutes"
                          select
                          label="Institutes"
                          defaultValue="Select Institute"
                          name="Insitute"
                          value={institute}
                          onChange={(e) => {
                            setInstitute(e.target.value);
                          }}
                          SelectProps={{
                            native: true,
                          }}
                          variant="standard"
                        >
                          {AvailableInstitutes.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      </Fade>
                    </Grid>
                  )}
                  {!data?.isProgram && (
                    <Grid
                      item
                      lg={!data?.isInstitute ? 3 : 4}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <Fade damping={0} direction="up" triggerOnce={true} delay={400}>
                        <TextField
                          id="enroll-select-Program"
                          select
                          label="Programs"
                          defaultValue="Select Program"
                          name="Program"
                          value={program}
                          onChange={(e) => {
                            setProgram(e.target.value);
                          }}
                          SelectProps={{
                            native: true,
                          }}
                          variant="standard"
                        >
                          {AvailablePrograms.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      </Fade>
                    </Grid>
                  )}
                  <Grid
                    item
                    lg={!data?.isProgram && !data?.isInstitute ? 3 : 4}
                    md={6}
                    sm={6}
                    xs={12}
                  >
                    <Fade damping={0} direction="up" triggerOnce={true} delay={400}>
                      <TextField
                        id="enroll-select-City"
                        select
                        label="Select City"
                        defaultValue="Select City"
                        name="City"
                        value={city}
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                        SelectProps={{
                          native: true,
                        }}
                        variant="standard"
                      >
                        {AvailableBranches.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Fade>
                  </Grid>
                  <Grid
                    item
                    lg={!data?.isProgram && !data?.isInstitute ? 3 : 4}
                    md={6}
                    sm={6}
                    xs={12}
                  >
                    <Fade damping={0} direction="up" triggerOnce={true} delay={400}>
                      <TextField
                        id="enroll-select-Price"
                        select
                        label="Select Price"
                        defaultValue="Select Price"
                        name="Price"
                        value={price}
                        onChange={(e) => {
                          setPrice(e.target.value);
                        }}
                        SelectProps={{
                          native: true,
                        }}
                        variant="standard"
                      >
                        {AvailablePriceRange.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Fade>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="institute-table-form-btn">
                      <Fade damping={0} direction="up" triggerOnce={true} delay={400}>
                        <Button className="institute-btns" onClick={() => { fetchData() }}>Search Program <ArrowRightOutlineIcon /></Button>
                      </Fade>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {appliedSearch && rows.length > 0 && <Grid item xs={12}>
              <Paper>
                <TableContainer component={Paper} className="conditions-table">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow id="heading">
                        <TableCell
                          key={'enquiry'}
                          component="th"
                          className="table-main-title"
                          style={{
                            backgroundColor: "#283676",
                            color: "white",
                            width: "auto",
                            height: "65px",
                          }}
                        >
                          Enquiry
                        </TableCell>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            component="th"
                            className="table-main-title"
                            style={{
                              backgroundColor: "#283676",
                              color: "white",
                              width: "auto",
                              height: "65px",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows &&
                        rows
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row: any, index: number) => {
                            return (
                              <TableRow
                                hover
                                id={`body-${index}`}
                                tabIndex={-1}
                                key={row.code}
                              >
                                <TableCell
                                  key={`enquiry-${index}`}
                                  // align={column.align}
                                  style={{ height: "60px", width: "60px", padding:'10px' }}
                                >
                                  <Image
                                    onClick={() => { enquiryNow(row) }}
                                    src={WP.src}
                                    blurDataURL={WP.blurDataURL}
                                    width={30}
                                    height={30}
                                    quality={100}
                                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                                    style={{ height: "auto" }}
                                    className="points-icon-box"
                                    alt="Enquiry on Whatsapp"
                                  ></Image>
                                  <Link prefetch={false} className="nav-item-link" href="tel:61404797620">
                                    <PhoneIcon className="contact-txt-icon" />
                                  </Link>
                                </TableCell>
                                {columns.map((column) => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell
                                      key={column.id}
                                      // align={column.align}
                                      style={{ height: "60px" }}
                                    >
                                      {column.id === 'Fees' && 'AUD $'}
                                      {column.format &&
                                        typeof value === "number"
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  className="table-pagination"
                  count={rows && rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Grid>}
            {appliedSearch && rows.length == 0 && <Grid item sm={12} xs={12}>
              <Box className="institute-title">
                <Fade damping={0} direction="up" triggerOnce={true} delay={400}>
                  <Typography variant="h2" className="sub-title">
                    No Record Found
                  </Typography>
                </Fade>
              </Box>
            </Grid>}
          </Grid>
        </Container>
      </InstituteTableSection>
    </SectionSapce>
  );
};

export default InstituteTable;
